import React, { useEffect, useState } from 'react';

const BlogsGrid = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [taxonomyCategory, setTaxonomyCategory] = useState([]);
    const [error, setError] = useState(null);
    const [filters, setFilters] = useState({
        category: 'all',
    });
    const [filteredResults, setFilteredResults] = useState([]);
    console.log(blogs);
    useEffect(async () => {
        await Promise.all([fetchBlogs(), fetchCategories()]);

        setLoading(false);
    }, []);

    const fetchBlogs = async () => {
        try {
            const response = await fetch(
                `${wp_ajax.api_url}joejeffries/get-blogs/`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );

            const responseJson = await response.json();
            setBlogs(Object.values(responseJson));
            console.log(blogs);
        } catch (error) {
            setError(error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await fetch(
                `${wp_ajax.api_url}joejeffries/get-terms/?term=category`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );

            const responseJson = await response.json();

            setTaxonomyCategory(Object.values(responseJson));
        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => {
        if (filters.category === 'all') {
            setFilteredResults(blogs);

            return;
        }

        const filteredResults = blogs.filter((blog) => {
            if (blog.terms === null) {
                return;
            }

            if (filters.category === 'all') {
                return blog.terms.find(
                    (term) =>
                        term.type === 'job_type' &&
                        term.slug === filters.job_type,
                );
            }

            return blog.terms.find(
                (term) =>
                    term.type === 'category' && term.slug === filters.category,
            );
        });

        setFilteredResults(filteredResults);
    }, [filters, loading]);

    const handleCategoryChange = (event) => {
        const category = event.target.value;

        setFilters({ ...filters, category });
    };

    if (loading || error) return <div className="u-loader"></div>;

    return (
        <>
            <section className="c-filter-bar c-filter-bar--blogs content-section content-section--reduced-padding">
                <div className="filter-bar filter-bar--select-wrapper">
                    {taxonomyCategory && (
                        <>
                            <label htmlFor="blog-category">Category:</label>
                            <select
                                id="blog-category"
                                onChange={handleCategoryChange}
                                className="filter-bar__select">
                                <option value="all">All</option>
                                {taxonomyCategory.map((category, idx) => (
                                    <option
                                        key={`category-${idx}`}
                                        value={category.slug}
                                        dangerouslySetInnerHTML={{
                                            __html: category.name,
                                        }}></option>
                                ))}
                            </select>
                        </>
                    )}
                </div>
            </section>
            <div className="blog-listing">
                {filteredResults &&
                    filteredResults.map((item, index) => {
                        return (
                            <a
                                className="blog-listing__item"
                                href={item.permalink}
                                target={item.external_link ? '_blank' : '_self'}
                                key={index}>
                                <div
                                    className={`blog-listing__bg blog-listing__bg--${item.ID}`}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: item.image_markup,
                                        }}></div>
                                </div>
                                <div className="blog-listing__item-content">
                                    <h4 className="u-text--black">
                                        {item.title}
                                    </h4>
                                    {item.excerpt && (
                                        <p className="u-text--black u-small-text">
                                            {item.title}
                                        </p>
                                    )}
                                </div>
                            </a>
                        );
                    })}
            </div>
            {filteredResults.length == 0 && (
                <h2 className="blog-listing__no-results">
                    No blogs match your filter criteria.
                </h2>
            )}
        </>
    );
};

export default BlogsGrid;
