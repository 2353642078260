const burger = document.querySelector('[data-behaviour="burger-menu"]');
const header = document.querySelector('[data-behaviour="header"]');
const openChildMenus = document.querySelectorAll(
    '[data-behaviour="mobile-open-child-menu"]',
);
const childMenu = document.querySelector('[data-behaviour="child-menu"]');

const mobileNav = document.querySelector('[data-behaviour="mobile-nav"]');
const body = document.querySelector('body');

if (burger != null) {
    burger.addEventListener(
        'click',
        (e) => {
            e.preventDefault();
            body.classList.toggle('locked');
            burger.classList.toggle('site-header__burger--active');
            mobileNav.classList.toggle('site-header__nav--active');
            header.classList.toggle('site-header--active');

            if (burger.classList.contains('site-header__burger--active')) {
                openChildMenus.forEach((openMenu) => {
                    openMenu.addEventListener('click', (ev) => {
                        ev.preventDefault();
                        openMenu.classList.toggle('active');
                        openMenu.nextElementSibling.classList.toggle(
                            'site-header__child-menu-wrapper-active',
                        );
                    });
                });
            }
        },
        false,
    );
}
