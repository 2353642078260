const header = document.querySelector('[data-behaviour="header"]');
const headerTop = header.offsetTop;
function fixedNavigation() {
    if (window.scrollY > headerTop) {
        header.classList.add('site-header--fixed');
    } else {
        header.classList.remove('site-header--fixed');
    }
}

window.addEventListener('scroll', fixedNavigation);
