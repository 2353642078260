// Add your js(x) component file imports/React setup here
import React from 'react';
import ReactDOM from 'react-dom';
import BlogsGrid from './BlogsGrid';

const BlogsGridElement = document.querySelector(
    '[data-react="render-blogs-grid"]',
);

if (BlogsGridElement) {
    ReactDOM.render(<BlogsGrid />, BlogsGridElement);
}
